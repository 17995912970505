<template>
  <b-overlay
      :show="showOverlay"
      rounded="sm"
  >
    <div v-if="Categories !== null">
      <b-modal
          id="modal-delete"
          cancelTitle="خیر"
          centered
          ok-title="بله"
          @ok="DeleteCategory"
      >
        <span>آیا از حذف کردن این دسته بندی اطمینان دارید ؟ </span>
      </b-modal>
      <b-modal
          v-if="showUpdateModal"
          id="modal-edit"
          cancel-title="بستن"
          centered
          ok-title="ثبت"
          title="بروزرسانی دسته بندی"
          @ok.preventDefault()="UpdateCategory"
      >
        <b-row v-if="SelectedCategory!==null">
          <b-col cols="12">
            <b-media class="my-2">
              <template>
                <b-avatar
                    ref="previewEl"
                    :src="picForUpdate"
                    class="w-100 cursor-pointer flex-wrap"
                    rounded
                    size="300px"
                    @click.native="$refs.refInputEl1.click()"
                >
                  <feather-icon v-if="picForUpdate === null" icon="PlusSquareIcon" size="100"/>
                </b-avatar>
              </template>
              <div class="d-none flex-wrap">
                <b-button
                    variant="primary"
                >
                  <input
                      ref="refInputEl1"
                      accept="image/*"
                      class="d-none"
                      type="file"
                      @input="makeBase64PicForUpdate($event,'picForUpdate')"
                  >
                  <span class="d-none d-sm-inline">+</span>
                  <feather-icon
                      class="d-inline d-sm-none"
                      icon="EditIcon"
                  />
                </b-button>
              </div>
            </b-media>

            <b-form-group>
              <label>نام دسته بندی</label>
              <b-form-input
                  v-model="SelectedCategory.name"
                  placeholder="نام دسته بندی"
                  type="text"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <h2>سئو</h2>

          </b-col>
          <b-col class="" md="6">
            <b-form-group
                class=""
                label="عنوان"
                label-for="blog-edit-title"
            >
              <b-form-input
                  id="blog-edit-seoTitle2"
                  v-model="SelectedCategory.seoTitle"
              />
            </b-form-group>
            <span :class="{'text-danger':SelectedCategory.seoTitle.length>60}"
                  class="">تعداد کاراکترهای مجاز <small>{{
                newCategory.seoTitle.length
              }}</small><small
                class="text-secondary">/</small><small class="text-secondary">60</small></span>
          </b-col>
          <b-col md="6">
            <b-row>
              <b-col cols="12">
                <b-form-group
                    class=""
                    label="آدرس"
                    label-for="blog-edit-seourl"
                >
                  <b-form-input
                      id="blog-edit-seourl2"
                      ref="myInput"
                      v-model="allCharactersToDashUpdate"
                      v-on:focus="$event.target.select()"
                  />
                </b-form-group>
              </b-col>

            </b-row>
          </b-col>
          <!-- Field:  description -->
          <b-col class="mt-2" cols="12">
            <b-form-group
                class="mb-2"
                label="توضیحات"
                label-for="blog-content"
            >
              <b-form-textarea
                  v-model="SelectedCategory.seoDescription"
              ></b-form-textarea>
            </b-form-group>
            <span :class="{'text-danger':SelectedCategory.seoDescription.length>160}"
                  class="">تعداد کاراکترهای مجاز <small>{{
                SelectedCategory.seoDescription.length
              }}</small><small
                class="text-secondary">/</small><small class="text-secondary">160</small></span>
          </b-col>
        </b-row>
      </b-modal>
      <b-modal
          id="modal-Add"
          cancel-title="بستن"
          centered
          ok-title="ثبت"
          title="افزودن دسته بندی (اصلی)"
          @ok.preventDefault()="AddCategory"
      >
        <b-row>
          <b-col cols="12">
            <b-media class="my-2">
              <template>
                <b-avatar
                    ref="previewEl"
                    :src="pic"
                    class="w-100 cursor-pointer flex-wrap"
                    rounded
                    size="300px"
                    @click.native="$refs.refInputEl1.click()"
                >
                  <feather-icon v-if="newCategory.fileData === null" icon="PlusSquareIcon" size="100"/>
                </b-avatar>
              </template>
              <div class="d-none flex-wrap">
                <b-button
                    variant="primary"
                >
                  <input
                      ref="refInputEl1"
                      accept="image/*"
                      class="d-none"
                      type="file"
                      @input="makeBase64Pic($event,'pic')"
                  >
                  <span class="d-none d-sm-inline">+</span>
                  <feather-icon
                      class="d-inline d-sm-none"
                      icon="EditIcon"
                  />
                </b-button>
              </div>
            </b-media>

            <b-form-group>
              <label>نام دسته بندی</label>
              <b-form-input
                  v-model="newCategory.name"
                  placeholder="نام دسته بندی"
                  type="text"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <h2>سئو</h2>
          </b-col>
          <b-col class="" md="6">
            <b-form-group
                class=""
                label="عنوان"
                label-for="blog-edit-title"
            >
              <b-form-input
                  id="blog-edit-seoTitle"
                  v-model="newCategory.seoTitle"
              />
            </b-form-group>
            <span :class="{'text-danger':newCategory.seoTitle.length>60}"
                  class="">تعداد کاراکترهای مجاز <small>{{
                newCategory.seoTitle.length
              }}</small><small
                class="text-secondary">/</small><small class="text-secondary">60</small></span>
          </b-col>
          <b-col md="6">
            <b-row>
              <b-col cols="12">
                <b-form-group
                    class=""
                    label="آدرس"
                    label-for="blog-edit-seourl"
                >
                  <b-form-input
                      id="blog-edit-seourl"
                      ref="myInput"
                      v-model="allCharactersToDash"
                      v-on:focus="$event.target.select()"
                  />
                </b-form-group>
              </b-col>

            </b-row>
          </b-col>
          <!-- Field:  description -->
          <b-col class="mt-2" cols="12">
            <b-form-group
                class="mb-2"
                label="توضیحات"
                label-for="blog-content"
            >
              <b-form-textarea
                  v-model="newCategory.seoDescription"
              ></b-form-textarea>
            </b-form-group>
            <span :class="{'text-danger':newCategory.seoDescription.length>160}"
                  class="">تعداد کاراکترهای مجاز <small>{{
                newCategory.seoDescription.length
              }}</small><small
                class="text-secondary">/</small><small class="text-secondary">160</small></span>
          </b-col>
        </b-row>


      </b-modal>
      <!-- Table Container Card -->
      <b-card
          class="mb-0"
          no-body
      >
        <b-col class="my-2" lg="12" md="12">
          <b-row>
            <b-col class="my-2" lg="8" md="12">
              <debouncer :placeHolder="`جستجو بر اساس نام دسته بندی`" @setValue="getsearchValue"></debouncer>

            </b-col>
            <b-col class="my-2" lg="2" md="12">
              <b-button
                  class="w-100"
                  v-b-modal.modal-Add
                  variant="success"

              >
                افزودن دسته بندی جدید
              </b-button>
            </b-col>
            <b-col class="my-2" lg="2" md="12">
              <clear-sorts-btn @clear="sortBy=''"></clear-sorts-btn>
            </b-col>
          </b-row>
        </b-col>


        <b-table
            ref="refUserListTable"
            :fields="myTableColumns"
            :items="Categories"
            bordered
            :sort-by.sync="sortBy"
            class="position-relative"
            empty-text="موردی موجود نیست!"
            primary-key="id"
            responsive
            show-empty
            striped
        >

          <template #cell(avatar)="data">
            <b-avatar :src="baseURL+data.item.fileData" size="75" square/>
          </template>


          <template #cell(operation)="data">
            <div class="d-flex align-items-center justify-content-between">

              <div v-b-modal.modal-delete
                   class="cursor-pointer d-flex flex-row"
                   @click="SetSelectedCategory(data.item)"
              >
                <feather-icon class="text-danger" icon="TrashIcon" size="20"/>
              </div>

              <div v-b-modal.modal-edit
                   class="cursor-pointer d-flex flex-row"
                   @click="openModal(data.item)"
              >
                <feather-icon class="text-primary" icon="EditIcon" size="20"/>
              </div>
              <div class="cursor-pointer d-flex flex-row"
                   @click="$router.push({ name: 'pages-academy-AcademyCategory-id', params: { id: data.item.academyCategoryId }})"
              >
                <feather-icon class="text-success" icon="ExternalLinkIcon" size="25"/>
              </div>

            </div>
          </template>

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <!-- Pagination -->
            <b-col
                class="d-flex align-items-center justify-content-end "
                cols="12"
                sm="12"
            >

              <b-pagination
                  v-model="currentPage"
                  :per-page="perPage"
                  :total-rows="totalCount"
                  class="mb-0 mt-1 mt-sm-0"
                  first-number
                  last-number
                  next-class="next-item"
                  prev-class="prev-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon"
                                size="18"
                                style="transform: rotate(180deg)"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </div>


  </b-overlay>
</template>

<script>

import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BFormFile,
  BBadge, BDropdown, BDropdownItem, BFormTextarea, BPagination, BOverlay, BModal, BFormGroup, BFormSelect, BFormCheckbox
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import {
  CreateAcademyCategory,
  UpdateAcademyCategory,
  DeleteAcademyCategory,
  GetAllAcademyCategory
} from "@/libs/Api/academyCategory";
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import Helper from "@/libs/Helper";
import ClearSortsBtn from "@/views/components/clearSortsBtn.vue";
import Debouncer from "@/views/components/debouncer.vue";

export default {
  title: 'لیست دسته بندی (اصلی) دوره ها  - پنل ادمین انزا مد',
  name: 'AcademyCategories',
  data() {
    return {
      pic: null,
      baseURL: Helper.baseUrl,
      showOverlay: false,
      Categories: null,
      sortBy:'',
      showUpdateModal: false,
      totalCount: null,
      perPageOptions: [10, 20, 30, 40, 50],
      myTableColumns: [
        {
          key: 'academyCategoryId',
          label: 'شناسه',
          sortable:true
        },
        {
          key: 'avatar',
          label: 'تصویر دسته بندی',

        },
        {
          key: 'name',
          label: 'نام',
          sortable:true
        },
        {
          key: 'operation',
          label: 'عملیات'
        },


        // {
        //   key: 'SEO',
        //   label: 'SEO'
        // },
        // { key: 'parentId'},
      ],
      showDeleteModal: false,
      currentPage: 1,
      deleteItem: null,
      perPage: 10,
      search: '',
      SelectedCategory: null,
      picForUpdate: null,
      newCategory: {
        seourl: '',
        seoTitle: '',
        seoDescription: '',
        fileData: null,
        name: '',
        parentId: 0,
      }
    }
  },
  async created() {
    await this.GetAllCategory()
  },
  components: {
    Debouncer,
    ClearSortsBtn,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BFormFile,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BModal,
    BFormSelect,
    BFormTextarea,
    vSelect,
  },
  watch: {

    currentPage: function () {
      this.GetAllCategory()
    }
  },
  computed: {
    allCharactersToDashUpdate: {
      get: function () {
        return this.SelectedCategory.seourl;
      },
      set: function (newValue) {
        // This setter is getting number, replace all commas with empty str
        // Then start to separate numbers with ',' from beginning to prevent
        // from data corruption
        if (newValue) {
          this.SelectedCategory.seourl = newValue
          // Remove all characters that are NOT number
          this.SelectedCategory.seourl = this.SelectedCategory.seourl.replace(/[+*!@#$%^&*()_;:~`»«,×='"|<>/?{}\-/\.]/g, "-");
          this.SelectedCategory.seourl = this.SelectedCategory.seourl.replaceAll(' ', '-')
        } else if (!newValue || this.SelectedCategory.seourl === "") {
          this.SelectedCategory.seourl = null;
        }
      },
    },
    allCharactersToDash: {
      get: function () {
        return this.newCategory.seourl;
      },
      set: function (newValue) {
        // This setter is getting number, replace all commas with empty str
        // Then start to separate numbers with ',' from beginning to prevent
        // from data corruption
        if (newValue) {
          this.newCategory.seourl = newValue
          // Remove all characters that are NOT number
          this.newCategory.seourl = this.newCategory.seourl.replace(/[+*!@#$%^&*()_;:~`»«,×='"|<>/?{}\-/\.]/g, "-");
          this.newCategory.seourl = this.newCategory.seourl.replaceAll(' ', '-')
        } else if (!newValue || this.newCategory.seourl === "") {
          this.newCategory.seourl = null;
        }
      },
    },
  },
  methods: {
    getsearchValue(val){
      this.search = val
      this.GetAllCategory()
    },
    async UpdateCategory() {
      let _this = this
      _this.showOverlay = true;
      let updateAcademyCategory = new UpdateAcademyCategory(_this)

      updateAcademyCategory.setData(_this.SelectedCategory)
      await updateAcademyCategory.fetch(function (content) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `دسته بندی بروزرسانی شد`,
          },
        })
        _this.SelectedCategory = null
        _this.showOverlay = false;
        _this.GetAllCategory();
      }, function (error) {
        _this.showOverlay = false;
        console.log(error)
      })
    },
    openModal(data) {
      let _this = this;

      _this.showUpdateModal = true;
      _this.SelectedCategory = JSON.parse(JSON.stringify(data))
      if (_this.SelectedCategory.fileData !== '') {
        _this.picForUpdate = _this.baseURL + _this.SelectedCategory.fileData;
        _this.SelectedCategory.fileData = '';
      } else {
        _this.picForUpdate = null
      }
    },
    async AddCategory() {
      let _this = this;
      _this.showOverlay = true;
      if (_this.newCategory.name !== '' && _this.newCategory.seourl !== '' && _this.newCategory.seoTitle !== '' && _this.newCategory.seoDescription !== '') {
        let createAcademyCategory = new CreateAcademyCategory(_this);
        createAcademyCategory.setData(_this.newCategory)
        await createAcademyCategory.fetch(function (content) {
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              title: `عملیات موفق`,
              icon: 'CheckIcon',
              variant: 'success',
              text: `دسته بندی ساخته  شد`,
            },
          })
          _this.GetAllCategory();
          _this.newCategory = {
            seourl: '',
            seoTitle: '',
            seoDescription: '',
            fileData: null,
            name: '',
            parentId: 0,
          }
          _this.showOverlay = false;
        }, function (error) {
          _this.showOverlay = false;
          console.log(error)
        })
      } else {
        _this.showOverlay = false;
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات ناموفق`,
            icon: 'AlertCircleIcon',
            variant: 'danger',
            text: `لطفا فیلد ها را پر کنید`,
          },
        })
      }
    },
    async DeleteCategory() {
      let _this = this
      let deleteAcademyCategory = new DeleteAcademyCategory(_this)
      deleteAcademyCategory.setParams({id: this.SelectedCategory.academyCategoryId})
      await deleteAcademyCategory.fetch(function (content) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `دسته بندی  حذف شد.`,
          },
        })
        _this.GetAllCategory();
      }, function (error) {
        console.log(error)
      })
    },
    SetSelectedCategory(item) {
      this.SelectedCategory = JSON.parse(JSON.stringify(item))
    },
    async GetAllCategory() {
      let _this = this
      let getAllAcademyCategory = new GetAllAcademyCategory(_this)
      let data = {
        pageNumber: _this.currentPage,
        searchCommand: _this.search,
        count: 10,
        parentId: 0
      }
      getAllAcademyCategory.setParams(data)
      await getAllAcademyCategory.fetch(function (content) {
        _this.Categories = content.academyCategories
        _this.totalCount = content.categoriesCount
      }, function (error) {
        console.log(error)
      })
    },
    async readAsDataURL(file) {
      return new Promise((resolve, reject) => {
        const fr = new FileReader();
        fr.onerror = reject;
        fr.onload = () => {
          resolve(fr.result.split(",")[1]);
        }
        fr.readAsDataURL(file);
      });
    },
    async makeBase64Pic(e, index) {
      const _this = this;
      let file = e.target.files[0];
      _this[index] = URL.createObjectURL(file);
      _this.newCategory.fileData = await _this.readAsDataURL(file);
    },
    async makeBase64PicForUpdate(e, index) {
      const _this = this;
      let file = e.target.files[0];
      _this[index] = URL.createObjectURL(file);
      _this.SelectedCategory.fileData = await _this.readAsDataURL(file);
    },
  },
}
</script>

<style scoped>

</style>
